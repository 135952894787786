<template>
  <div>
    <div class="row mt-3">
      <div class="col-md-2">
        <div class="">
          <b-form-group
              class="mb-0">
            <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
            ></b-form-select>
          </b-form-group>
        </div>
      </div>
      <div class="col-md-2">

        <download-excel
            :data="items"
            class="btn btn-primary"
            :fields="json_fields"
            worksheet="Agent wise Patient list"
            name="Agent wise Patient list">
          <i class="fas fa-file-excel"></i> Export Excel
        </download-excel>


      </div>
      <div class="col-md-8">
        <b-form-group
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0">
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"/>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
            </b-input-group-append>

          </b-input-group>

        </b-form-group>
      </div>

    </div>

    <div class="row">
      <div class="col-md-12">
        <b-table
            striped hover
            :items="filtered"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            :filter-included-fields="filterOn"
            thead-class="bg-primary text-white"
            show-empty
            class="mt-10">

          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filters[field.key]" :placeholder="field.label" class="form-control">
            </td>
          </template>


          <template #cell(image)="row">
            <div class="is_online" v-if="row.item.image">
              <img :src="userPhoto(row.item.image)" alt="image"
                   class="user_image" v-if="row.item.image"/>
              <img src="../../../assets/img/blank.png" alt="" class="user_image" v-else>
              <i :class="`${row.item.is_online == '1' ? 'text-green ' : 'text-danger'} fas fa-circle is_online_icon`"></i>
            </div>
          </template>


        </b-table>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <b-pagination
            v-model="currentPage"
            :total-rows="items.length"
            :per-page="perPage"
            size="lg"
            class="my-0"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export default {
  name: "PatientList",
  data() {
    return {
      downloadLoading: true,
      filename: '',
      autoWidth: true,
      bookType: 'xlsx',
      items: [],
      json_fields: {
        "Fullname": {
          field: "fullname",
        },
        "Slug": {
          field: "slug",
        },
        "Phone number": {
          field: "phone_number",
        },
        "Age": {
          field: "age",
        },
        "Wallet": {
          field: "wallet",
        },
      },
      columns: [
        {
          key: 'fullname',
          label: 'Fullname',
        },
        {
          key: 'slug',
          label: 'Slug',
        },
        {
          key: 'phone_number',
          label: 'Phone number',
        },
        {
          key: 'age',
          label: 'Age',
        },
        {
          key: 'wallet',
          label: 'Wallet',
        }
      ],
      loading: false,
      currentPage: 1,
      perPage: 20,
      pageOptions: [10, 20, 40, 60, {value: 100, text: "Show a lot"}],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      localActive: false,
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'image',
          label: 'Image',
          sortable: true
        },
        {
          key: 'fullname',
          label: 'Fullname',
          sortable: true
        },
        {
          key: 'slug',
          label: 'Slug',
          sortable: true
        },
        {
          key: 'phone_number',
          label: 'Phone number',
          sortable: true
        },
        {
          key: 'age',
          label: 'Age',
          sortable: true,
        },
        {
          key: 'wallet',
          label: 'Wallet',
          sortable: true,
        }
      ],
      filter: null,
      filterOn: [],
      filters: {
        id: '',
        fullname: '',
        phone_number: '',
        age: '',
        wallet: '',
        slug: '',
      },
      startDate: null,
      endDate: null,
      show: true,
      base_url: process.env.VUE_APP_API_URL,
      users: [],

    }
  },
  metaInfo: {
    title: 'Orko Health Ltd | Agent wise patient list',
    htmlAttrs: {
      lang: 'en-US'
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Agent wise patient lists", route: "/agent-wise-user-list"},
    ]);
  },
  created() {
    this.getPatientLists();
  },
  computed: {
    rows() {
      return this.users.length
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key}
          })
    },
    filtered() {

      let startDate = this.localizeDate(this.startDate);
      let endDate = this.localizeDate(this.endDate);

      const filtered = this.items.filter(item => {
        const itemDate = new Date(item.registration_date)
        if (startDate && endDate) {
          return startDate <= itemDate && itemDate <= endDate;
        }
        if (startDate && !endDate) {
          return startDate <= itemDate;
        }
        if (!startDate && endDate) {
          return itemDate <= endDate;
        }
        return Object.keys(this.filters).every(key =>
            String(item[key]).includes(this.filters[key]))
      })

      return filtered.length > 0 ? filtered : [{
        id: '',
        fullname: '',
        phone_number: '',
        age: '',
        wallet: '',
        slug: '',
      }]
    },
    filteredData() {
      let filterType = this.selectedType;
      let startDate = this.localizeDate(this.startDate);
      let endDate = this.localizeDate(this.endDate);
      const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
      return itemsByType
          .filter(item => {
            const itemDate = new Date(item.registration_date)
            if (startDate && endDate) {
              return startDate <= itemDate && itemDate <= endDate;
            }
            if (startDate && !endDate) {
              return startDate <= itemDate;
            }
            if (!startDate && endDate) {
              return itemDate <= endDate;
            }
            return true;
          })
    }
  },
  methods: {

    getPatientLists() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/partner/agent/patient/list/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            this.items = response.data.data
          })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return '../../../public/media/users/blank.png'
      }
    },
    localizeDate(date) {
      if (!date || !date.includes('-')) return date
      const [yyyy, mm, dd] = date.split('-')
      return new Date(`${mm}/${dd}/${yyyy}`)
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
    },
  },
}
</script>
<style>
.hide {
  display: none;
}

.text-green {
  color: green;
}

.is_online {
  position: relative;
}

.is_online_icon {
  position: absolute;
  left: 0;
  bottom: 4px;
  margin-left: 14px;
  font-size: 12px;
}

.user_image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  text-align: center;
}
</style>